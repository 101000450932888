import $ from "jquery"

window.Zretel or= {}

class window.Zretel.Application

  @pageInitialize: () ->
    $('select').selectpicker('destroy')
    # console.log 'initialize'

  @ready: () ->
    $('select').selectpicker('destroy')
    window.Zretel.RowClickability.bindEvents()

    # this.tinyMce()

    this.customConfirm()
    $('.sortable').railsSortable();
    # $('select:not(.select2)').selectpicker();
    $('select.select2').select2()

    $('input#my-tokenfield').tokenfield()

    $('#my-tokenfield').on 'tokenfield:createtoken', (event) ->
      existingTokens = $(this).tokenfield('getTokens')
      $.each existingTokens, (index, token) ->
        if token.value == event.attrs.value
          event.preventDefault()
        return
      return



    $("#manual-token").on 'click', ->
      inputString = $(".token-input").val().split(",")
      for t in inputString
        $("input.tokenize").tokenfield("createToken", t)
      $(".token-input").val("")
      $(".token-input").focus()


    $(document).on 'click', '[data-toggle="lightbox"]', (event) ->
      event.preventDefault()

    $(".best_in_place").best_in_place()

  @hideTurboProgressBar: ()->
    $('.selectpicker').selectpicker('refresh')
    Turbolinks.controller.adapter.progressBar.setValue(100)
    Turbolinks.controller.adapter.progressBar.hide()

  @positiveRounded: (number)->
    if number > 0
      Math.round number
    else
      0

  @positiveMeasure: (axis, measure)->
    if axis > 0
      Math.round measure
    else
      Math.round(measure + axis)


  @updateCropInput: (prefix, suffix, coords)->
    if $.isNumeric(coords.x) and $.isNumeric(coords.y) and $.isNumeric(coords.width) and $.isNumeric(coords.height)
      console.log $('#' + prefix + 'crop_x' + suffix).length
      $('#' + prefix + 'crop_x' + suffix).val window.Zretel.Application.positiveRounded(coords.x)
      $('#' + prefix + 'crop_y' + suffix).val window.Zretel.Application.positiveRounded(coords.y)
      $('#' + prefix + 'crop_w' + suffix).val window.Zretel.Application.positiveMeasure(coords.x, coords.width)
      $('#' + prefix + 'crop_h' + suffix).val window.Zretel.Application.positiveMeasure(coords.y, coords.height)

  @tinyMce: ()->
     tinymce.init
      selector: 'textarea.tinymce'
      skin: false
      plugins: ['table', 'lists']


  @customConfirm: ()->
    #Override the default confirm dialog by rails

    $.rails.allowAction = (link) ->
      if link != null
        return true unless link.attr('data-confirm')
        $.rails.showConfirmDialog(link)
        false

    $.rails.confirmed = (link) ->
      if link != null
        link.removeAttr('data-confirm')
        link.trigger('click.rails')

    $.rails.showConfirmDialog = (link) ->
      if link != null
        message = link.attr 'data-confirm'
        html = """
              <div class="modal" id="confirmationDialog" tabindex="-1" role="dialog" aria-labelledby="modalConfirmationCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <h1 class="modal-title" id="modalConfirmationCenterTitle">
                        <center> #{message} </center>
                      </h1>
                    </div>
                    <div class="modal-footer">
                      <a data-dismiss="modal" class="btn">#{link.data('cancel')}</a>
                     <a data-dismiss="modal" class="btn btn-primary confirm">#{link.data('ok')}</a>
                    </div>
                  </div>
                </div>
              </div>
              """
        $(html).modal()
        $('#confirmationDialog .confirm').on 'click', ->
          $.rails.confirmed(link)

  @passwordEye = (eye_class, input_id) ->
    $(eye_class).on 'click', ->
      if $(input_id).attr('type') == 'password'
        $(input_id).attr('type', 'text');
      else
        $(input_id).attr('type', 'password');

  @copyLink: ()->
    $('.copy-link').on 'click', (e) ->
      console.log('cl')
      e.preventDefault()

      $temp = $("<input>")
      $("body").append $temp
      $temp.val($(this).attr('url')).select()
      document.execCommand "copy"
      $temp.remove()
