

window.Zretel or= {}

class window.Zretel.Training

  @formEvents: () ->

    window.Zretel.Training.paymentTypeEvents()
    #    window.Zretel.Training.employmentDepartmentEvents()
    window.Zretel.Training.templateName()
    window.Zretel.Training.templateAccreditation()
    window.Zretel.Training.applicationIco()

    window.Zretel.Training.correspondence()
    $('#training_application_different_addresses').on 'change', ->
      window.Zretel.Training.correspondence()

  @templateName: () ->
    $('#training_template_name').on 'change', ->
      $.ajax
        url: '/skoleni/template_form'
        method: 'GET'
        data:
          template_name: $(this).val()

  @templateAccreditation: () ->
    $('#training_template_accreditation').on 'change', ->
      $.ajax
        url: '/skoleni/template_form'
        method: 'GET'
        data:
          template_accreditation: $(this).val()
          template_name: $('#training_template_name').val()

  @applicationIco: () ->
    $('input#training_application_ico').on 'change', ->
      $.ajax '/ares',
      type: 'POST'
      data:
        ico: $(this).val()

      if $(this).val == ''
        $('.form-group.training_repayment_type').removeClass('hidden')
      else
        $('.form-group.training_repayment_type').addClass('hidden')

  @correspondence: () ->
    if $('#training_application_different_addresses').prop('checked')
      $('#correspondence').removeClass('hidden')
    else
      $('#correspondence').addClass('hidden')
      $('#correspondence input').val('')

  @updateFieldsIndex: () ->
    index = $('.training-fieldset').length.toString()
    $('.training-fieldset').attr('id', 'training-fieldset-' + index)
    $('.training-fieldset:last .place').attr("id", index + '_training_place')
    $('.training-fieldset:last .seo-title').attr("id", index + '_seo_title')
    $('.training-fieldset:last .seo-image').attr("id", index + '_seo_image')
    $('.training-fieldset:last .seo-image-preview').attr("id", index + '_seo_image_preview')

    $('#' + index + '_training_place').on 'change', ->
      $.ajax
        url: '/skoleni/update_seo_title'
        method: 'GET'
        data:
          index: index
          place_id: $(this).val()
          training_name: $(this).attr('training-name')


    return index

  @paymentTypeEvents: () ->
    $('.payment_type_radio').on 'change', ->
      if $('#training_application_payment_type_company').is(':checked')
        $('.form-group.company_field, #add-person, .address_field').removeClass('hidden')
        $('.employment_department_group').addClass('hidden')
        $('.different_addresses_wrapper').removeClass('hidden')
      else if $('#training_application_payment_type_self').is(':checked')
        $('.form-group.company_field, .employment_department_group, #add-person').addClass('hidden')
        $('.form-group.company_field, .employment_department_group, #add-person').val('')
        $('#training_application_email').val($('#training_application_application_people_attributes_0_email').val())
        $('#training_application_phone').val($('#training_application_application_people_attributes_0_phone').val())
        $('.address_field, .different_addresses_wrapper').removeClass('hidden')
      else if $('#training_application_payment_type_employment_department').is(':checked')
        $('.company_field, .address_field').addClass('hidden')
        $('.employment_department_group, #add-person').removeClass('hidden')
        if $('#training_application_different_addresses').length > 0
          $('#training_application_different_addresses').prop('checked', true)
          $('#training_application_different_addresses').trigger('change')
          $('.different_addresses_wrapper').addClass('hidden')

      console.log($(this))
      $.ajax
        url: '/skoleni/load_training_repayment_type'
        method: 'GET'
        data:
          training_id: $(this).attr('training_id')
          payment_type: $(this).val()


      $('.address_field input, .company_field input, .employment_department_group input:not([type="radio"])').val('')
    # $('.payment_type_radio').first().trigger('change')

  @employmentDepartmentEvents: () ->
    $('.employment_department_type_radio').on 'change', ->
      if $('#training_application_employment_department_type_request').is(':checked')
        $('.employment_department_field').removeClass('hidden')
      else if $('#training_application_employment_department_type_approved').is(':checked')
        $('.employment_department_field').addClass('hidden')
        $('.employment_department_field input').val('')

  @publicFilterEvents: () ->
    $('.filter-select').each ->
      placeholder = $(this).attr('placeholder')
      $(this).selectpicker
        noneSelectedText: placeholder

    $('.filter-select').on 'changed.bs.select', ->
      window.Zretel.Training.submitPublicFilter($(this))

    $('.form-control[type="search"]').on 'change', ->
      window.Zretel.Training.submitPublicFilter($(this))

    $('#clear-filter').on 'click', (e) ->
      e.preventDefault()
      $('.form-control[type="search"]').val('')
      $('.filter-select').each ->
        $(this).selectpicker('deselectAll').selectpicker('refresh')
      window.Zretel.Training.submitPublicFilter($('#clear-filter'), true)

  @submitPublicFilter: (target, clear = false) ->
    form = target.closest('form')
    if clear
      form.find('select').each (index, element) ->
        $element = $(element)
        $element.val(null)
        $element.selectpicker('refresh')

    form.find('.btn[type="submit"]').trigger('click')
    $('#trainings').html('')
    $('.spinner').removeClass('hidden')




# $('.is_company_radio input').first().trigger("change")
